import { store } from '../store'

export default class OrderService {
    ordersType = 'Futures'

    async getTrades (id, params) {
        return await store.dispatch('trade/get' + this.ordersType + 'Trades', {
            _params: {
                orderId: id
            },
            _data: params
        })
    }
}
