<template>
    <div class="grid">
        <div class="col-12">
            <div class="card">
                <ProgressBar
                    v-if="getOrdersLoading && orders"
                    mode="indeterminate"
                    class="custom-progress-bar"/>
                <DataTable
                    v-if="getOrdersLoading && !orders"
                    class="p-datatable-sm"
                    responsiveLayout="scroll"
                    :value="new Array(10)"
                >
                    <template #header>
                        <div class="flex justify-content-between mb-4">
                            <div class="flex">
                                <SelectButton
                                    v-model="ordersType"
                                    option-label="name"
                                    option-value="slug"
                                    option-disabled="disabled"
                                    class="mr-2"
                                    :options="ordersTypes"/>
                                <div class="text-left">
                                    <MultiSelect
                                        optionLabel="header"
                                        placeholder="Выбрать колонки"
                                        style="max-width: 20em"/>
                                </div>
                            </div>
                            <div>
                                <Button
                                    type="button"
                                    icon="pi pi-filter-slash"
                                    label="Фильтр"
                                    :class="['p-button-outlined', 'p-button-sm']"/>
                            </div>
                        </div>
                    </template>
                    <Column v-for="(col, index) of selectedColumns"
                            :field="col.field"
                            :header="col.header"
                            :key="col.field + '_' + index">
                        <template #body>
                            <Skeleton></Skeleton>
                        </template>
                    </Column>
                </DataTable>
                <DataTable
                    v-else
                    ref="dt"
                    class="p-datatable-sm"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    dataKey="id"
                    columnResizeMode="fit"
                    responsiveLayout="scroll"
                    currentPageReportTemplate="От {first} до {last} из {totalRecords} записей"
                    v-model:expandedRows="expandedRows"
                    :resizableColumns="true"
                    :totalRecords="getOrdersTotal"
                    :value="orders"
                    :lazy="true"
                    :loading="getOrdersLoading"
                    :rows="getOrdersPagination.take"
                    :paginator="true"
                    :rowsPerPageOptions="[50,100,250]"
                    @sort="onSort($event)"
                    @page="onPage($event)"
                    @rowExpand="onRowExpand"
                >
                    <template #empty>
                        <Message severity="warn" :closable="false">Список ордеров пуст</Message>
                    </template>
                    <template #header>
                        <div class="flex justify-content-between mb-4">
                            <div class="flex">
                                <SelectButton
                                    v-model="ordersType"
                                    option-label="name"
                                    option-value="slug"
                                    option-disabled="disabled"
                                    class="mr-2"
                                    :options="ordersTypes"/>
                                <div class="text-left">
                                    <MultiSelect
                                        :modelValue="selectedColumns"
                                        :options="columns"
                                        optionLabel="header"
                                        @update:modelValue="onToggle"
                                        placeholder="Выбрать колонки"
                                        style="max-width: 20em"/>
                                </div>
                            </div>
                            <div>
                                <Chip
                                    v-for="(chip) in filterChips"
                                    :label="chip.header"
                                    :key="chip.field"
                                    class="mr-2"
                                    @remove="onChip(chip)"
                                    removable/>
                                <Button
                                    type="button"
                                    icon="pi pi-filter-slash"
                                    label="Фильтр"
                                    @click="filterDialog = !filterDialog"
                                    :class="[getOrdersFilter ? 'p-button' : 'p-button-outlined', 'p-button-sm'] "/>
                            </div>
                        </div>
                    </template>
                    <Column :expander="true" :headerStyle="ordersType === 'Futures'? 'width: 3rem' : '0rem'"/>
                    <Column v-for="(col, index) of selectedColumns"
                            :field="col.field"
                            :header="col.header"
                            :sortable="true"
                            :key="col.field + '_' + index">
                        <template #body="{data}" v-if="col.field === 'api_key_id'">
                            <strong>ID: {{ data.api_key_id }}</strong> {{ data.api_key ? data.api_key.note : '' }}
                        </template>
                        <template #body="{data}" v-else-if="col.field === 'status'">
                            <Tag
                                rounded
                                :icon="data.status  === 'CANCELED' ? 'pi pi-exclamation-triangle' : 'pi pi-check'"
                                :severity="data.status  === 'CANCELED' ? 'warn' : 'success'"
                                :value="data.status"></Tag>
                        </template>
                        <template #body="{data}" v-else-if="col.field === 'update_time'">
                            {{ $moment(data.update_time).format("DD MMM YYYY, HH:mm") }}
                        </template>
                    </Column>
                    <template #expansion="slotProps">
                        <div class="p-4">
                            <ProgressBar
                                v-if="(slotProps.data.id === getTradeLastRequestOrderId) && getTradeLoading"
                                mode="indeterminate"
                                class="custom-progress-bar"/>
                            <DataTable
                                v-else-if="slotProps.data.trades && slotProps.data.trades.length"
                                :value="slotProps.data.trades"
                                responsiveLayout="scroll">
                                <template #empty>
                                    <Message severity="warn">Список ордеров пуст</Message>
                                </template>
                                <Column v-for="(col, index) of tradeColumn"
                                        :field="col.field"
                                        :header="col.header"
                                        :key="col.field + '_' + index">
                                    <template #body="{data}" v-if="col.field === 'time'">
                                        {{ $moment(data.time).format("DD MMM YYYY, HH:mm") }}
                                    </template>
                                </Column>
                            </DataTable>
                            <Message v-else severity="warn">Список трейдов пуст</Message>
                        </div>
                    </template>
                </DataTable>
            </div>
        </div>
        <Dialog
            v-model:visible="filterDialog"
            :modal="true"
            :breakpoints="{'960px': '75vw', '640px': '100vw'}"
            :style="{width: '450px'}"
            header="Информация об акаунте"
            class="p-fluid">
            <div class="field">
                <label for="filterApiKey">Ключ</label>
                <MultiSelect
                    id="filterApiKey"
                    v-model="myFilter.api_key_id"
                    :options="filterKeysOptions ? filterKeysOptions : []"
                    :loading="filterKeysOptionsLoading"
                    :disabled="filterKeysOptionsLoading"
                    optionValue="id"
                    optionLabel="note"
                    show-clear
                    display="chip"
                    :placeholder="filterKeysOptionsLoading ? 'Загрузка ключей...' : 'Выбрать ключ'">
                    <template #option="slotProps">
                        <div class="p-multiselect-car-option">
                            <span>ID:{{ slotProps.option.id }} {{ slotProps.option.note }}</span>
                        </div>
                    </template>
                </MultiSelect>
            </div>
            <div class="field">
                <label for="filterSymbol">Пара</label>
                <MultiSelect
                    id="filterSymbol"
                    v-model="myFilter.symbol"
                    :options="filterSymbolsOptions ? filterSymbolsOptions : []"
                    :loading="filterSymbolsOptionsLoading"
                    :disabled="filterSymbolsOptionsLoading"
                    optionValue="symbol"
                    optionLabel="symbol"
                    show-clear
                    display="chip"
                    :placeholder="filterSymbolsOptionsLoading ? 'Загрузка пар...' : 'Выбрать пару'"/>
            </div>
            <div class="field">
                <label for="filterStatus">Статус</label>
                <MultiSelect
                    id="filterStatus"
                    v-model="myFilter.status"
                    :options="filterStatusesOptions ? filterStatusesOptions : []"
                    optionValue="id"
                    optionLabel="name"
                    show-clear
                    display="chip"
                    :placeholder="'Выбрать статус'"/>
            </div>
            <div class="field">
                <label for="filterUpdateTimeFrom">Дата обновления</label>
                <div class="flex field text-center">
                    <Calendar
                        id="filterUpdateTimeFrom"
                        v-model="myFilter.update_time_from"
                        :showTime="true"
                        hideOnDateTimeSelect
                        :manualInput="false"/>
                    -
                    <Calendar
                        id="filterUpdateTimeTo"
                        v-model="myFilter.update_time_to"
                        :showTime="true"
                        hideOnDateTimeSelect
                        :manualInput="false"/>
                </div>
            </div>
            <div class="field">
                <label for="filterPrice">Цена</label>
                <div class="flex field text-center">
                    <InputText v-model.number="myFilter.price[0]"/>
                    -
                    <InputText v-model.number="myFilter.price[1]"/>
                </div>
                <Slider
                    id="filterPrice"
                    v-model="myFilter.price"
                    :range="true"
                    :step="10"
                    :min="0"
                    :max="1000"
                />
            </div>
            <template #footer>
                <Button
                    label="Сбросить"
                    icon="pi pi-times"
                    class="p-button-text"
                    @click="storeFilter(getOrdersFilterDefault, true)"
                />
                <Button
                    label="Сохранить"
                    icon="pi pi-check"
                    class="p-button-text"
                    @click="storeFilter(myFilter)"
                />
            </template>
        </Dialog>
    </div>
</template>

<script>
import OrderService from '../services/OrderService'
import TradeService from '../services/TradeService'
import ApiKeyService from '../services/ApiKeyService'
import SymbolService from '../services/SymbolService'
import { computed, ref, reactive, watch } from 'vue'
import { useStore } from 'vuex'

export default {
    name: 'Orders',
    setup () {
        const orderService = new OrderService()
        const apiKeyService = new ApiKeyService()
        const symbolService = new SymbolService()
        const tradeService = new TradeService()
        const ordersTypes = [
            {
                slug: 'Futures',
                name: 'Фьючи',
                disabled: 0
            },
            {
                slug: 'Spot',
                name: 'Спот',
                disabled: 1
            },
            {
                slug: 'Margin',
                name: 'Марж',
                disabled: 1
            }
        ]

        const resetPagination = () => orderService.storeOrdersPagination({
            page: 1,
            take: 50
        })

        orderService.ordersType = ref(ordersTypes[0].slug)

        const myFilterDefault = () => {
            return {
                api_key_id: [],
                update_time_from: null,
                update_time_to: null,
                price: [],
                status: []
            }
        }
        orderService.storeOrdersFilterDefault(myFilterDefault())
        resetPagination()
        orderService.getOrders()
        apiKeyService.getApiKeys()
        symbolService.getSymbols()

        const store = useStore()
        const filterDialog = ref(false)
        const filterChips = ref([])
        const filterKeysOptions = computed(() => store.state.apiKey.getApiKeys)
        const filterKeysOptionsLoading = computed(() => store.state.apiKey.getApiKeysLoading)
        const filterSymbolsOptions = computed(() => store.state.symbol.getSymbols)
        const filterStatusesOptions = orderService.filterStatusesOptions()
        const filterSymbolsOptionsLoading = computed(() => store.state.symbol.getSymbolsLoading)
        const orders = computed(() => store.state.order['get' + orderService.ordersType.value + 'Orders'])
        const getOrdersLoading = computed(() => store.state.order['get' + orderService.ordersType.value + 'OrdersLoading'])
        const getOrdersFilter = computed(() => store.state.order['get' + orderService.ordersType.value + 'OrdersFilter'])
        const getOrdersTotal = computed(() => store.state.order['get' + orderService.ordersType.value + 'OrdersTotal'])
        const getOrdersPagination = computed(() => store.state.order['get' + orderService.ordersType.value + 'OrdersPagination'])
        const getOrdersFilterDefault = computed(() => store.state.order['get' + orderService.ordersType.value + 'OrdersFilterDefault'])
        const getTrades = computed(() => store.state.trade['get' + orderService.ordersType.value + 'Trades'])
        const expandedRows = ref([])
        const getTradeLastRequestOrderId = computed(() => store.getters['trade/lastOrderId'])
        const getTradeLoading = computed(() => store.state.trade['get' + orderService.ordersType.value + 'TradesLoading'])
        const statuses = orderService.filterStatuses()
        const columns = ref(orderService.columns())
        const selectedColumns = ref(columns.value)
        const tradeColumn = ref(orderService.tradeColumns())
        const myFilter = reactive(myFilterDefault())
        const onToggle = (val) => {
            selectedColumns.value = columns.value.filter(col => val.includes(col))
        }
        const checkChips = () => {
            filterChips.value = []
            columns.value.forEach((item) => {
                if (getOrdersFilter.value) {
                    const filters = Object.keys(getOrdersFilter.value)
                    if (filters.includes(item.field)) {
                        const filter = getOrdersFilter.value[item.field]
                        if (filter !== null) {
                            if (filter instanceof Array) {
                                if (filter.length) {
                                    filterChips.value.push(item)
                                }
                            } else {
                                filterChips.value.push(item)
                            }
                        }
                    }
                }
            })
        }

        const storeFilter = (data, reset = false) => {
            orderService.storeOrdersFilter(data)
            orderService.getOrders()
            filterDialog.value = false
            if (reset) {
                Object.assign(myFilter, myFilterDefault())
            }
            checkChips()
        }

        const onSort = (event) => {
            orderService.storeOrdersFilter({
                sort: event.sortField,
                sort_desc: event.sortOrder > 0 || null
            })
            orderService.getOrders()
        }

        const onPage = (event) => {
            orderService.storeOrdersPagination({
                page: ++event.page,
                take: event.rows
            })
            orderService.getOrders()
        }

        const onChip = (event) => {
            const key = {}
            key[event.field] = myFilterDefault()[event.field]
            Object.assign(myFilter, key)
            orderService.storeOrdersFilter(myFilter)
            orderService.getOrders()
        }

        const onRowExpand = async (event) => {
            await tradeService.getTrades(event.data.id)
            await orderService.setTradesToOrder('get' + orderService.ordersType.value + 'Orders', {
                orderId: event.data.id,
                trades: getTrades.value
            })
        }

        if (getOrdersFilter.value) {
            Object.assign(myFilter, getOrdersFilter.value)
            checkChips()
        }

        watch(orderService.ordersType, (state, prev) => {
            resetPagination()
            orderService.getOrders()
        })
        watch(filterDialog, (state, prevState) => {
            if (state) {
                apiKeyService.getApiKeys()
                symbolService.getSymbols()
            }
        })

        return {
            onSort,
            onPage,
            onChip,
            getOrdersTotal,
            statuses,
            myFilter,
            filterChips,
            getOrdersPagination,
            getOrdersFilterDefault,
            filterKeysOptions,
            filterKeysOptionsLoading,
            filterSymbolsOptions,
            filterSymbolsOptionsLoading,
            filterStatusesOptions,
            storeFilter,
            getOrdersFilter,
            filterDialog,
            getOrdersLoading,
            orders,
            onToggle,
            selectedColumns,
            columns,
            tradeColumn,
            onRowExpand,
            getTradeLoading,
            getTradeLastRequestOrderId,
            expandedRows,
            ordersType: orderService.ordersType,
            ordersTypes
        }
    }
}
</script>

<style scoped lang="scss">
.table-header {
    display: flex;
    justify-content: space-between;
}

.confirmation-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.order-badge {
    border-radius: 2px;
    padding: .25em .5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: .3px;

    &.status-active {
        background: #C8E6C9;
        color: #256029;
    }

    &.status-notactive {
        background: #FEEDAF;
        color: #8A5340;
    }
}

@media screen and (max-width: 960px) {
    ::v-deep(.p-datatable) {
        &.p-datatable-customers {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr {
                border-bottom: 1px solid var(--surface-d);

                > td {
                    text-align: left;
                    display: block;
                    width: 100% !important;
                    float: left;
                    clear: left;
                    border: 0 none !important;

                    &:last-child {
                        text-align: center;
                    }

                    .p-column-title {
                        padding: .4rem;
                        min-width: 30%;
                        display: inline-block;
                        margin: -.4rem 1rem -.4rem -.4rem;
                        font-weight: bold;
                    }

                    .p-rating {
                        display: inline-block;
                    }
                }
            }
        }
    }

    ::v-deep(.p-toolbar) {
        flex-wrap: wrap;

        .p-button {
            margin-bottom: .25rem;
        }
    }
}
</style>
