import { store } from '../store'

export default class OrderService {
    ordersType = {
        value: 'Futures'
    }

    async getOrders (params) {
        return await store.dispatch('order/get' + this.ordersType.value + 'Orders', {
            _data: params
        })
    }

    async storeOrdersFilter (params) {
        return await store.dispatch('order/get' + this.ordersType.value + 'OrdersFilter', params)
    }

    async storeOrdersFilterDefault (params) {
        return await store.dispatch('order/get' + this.ordersType.value + 'OrdersFilterDefault', params)
    }

    async storeOrdersPagination (params) {
        return await store.dispatch('order/get' + this.ordersType.value + 'OrdersPagination', params)
    }

    async setTradesToOrder (stateName, params) {
        return await store.dispatch('order/setTrades', { ...{ stateName: stateName }, ...params })
    }

    columns () {
        return [
            { field: 'api_key_id', header: 'Апи ключ' },
            { field: 'symbol', header: 'Пара' },
            { field: 'order_id', header: 'Order ID' },
            { field: 'client_order_id', header: 'Client Order ID' },
            { field: 'status', header: 'Статус' },
            { field: 'price', header: 'Цена' },
            { field: 'trades_sum_price', header: 't_price' },
            { field: 'trades_sum_qty', header: 't_qty' },
            { field: 'trades_sum_quote_qty', header: 't_quote_qty' },
            { field: 'trades_sum_commission', header: 't_commission' },
            { field: 'trades_sum_realized_pnl', header: 't_realized_pnl' },
            { field: 'update_time', header: 'Время обновления' }
        ]
    }

    tradeColumns () {
        return [
            { field: 'symbol', header: 'Пара' },
            { field: 'qty', header: 'qty' },
            { field: 'quote_qty', header: 'quote_qty' },
            { field: 'commission', header: 'Коммиссия' },
            { field: 'price', header: 'Цена' },
            { field: 'realized_pnl', header: 'realized_pnl' },
            { field: 'time', header: 'Время' }
        ]
    }

    filterStatuses () {
        return [
            { label: 'Активный', value: true },
            { label: 'Не активный', value: false }
        ]
    }

    filterStatusesOptions () {
        return [
            {
                id: 0,
                name: 'NEW'
            },
            {
                id: 1,
                name: 'PARTIALLY_FILLED'
            },
            {
                id: 2,
                name: 'FILLED'
            },
            {
                id: 3,
                name: 'CANCELED'
            },
            {
                id: 4,
                name: 'PENDING_CANCEL'
            },
            {
                id: 5,
                name: 'REJECTED'
            },
            {
                id: 6,
                name: 'EXPIRED'
            }
        ]
    }
}
